import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
// styles
import clsx from 'clsx';
import styles from './evp.module.css';
import itemStyles from '../components/item/cases.module.css';
// components
import Layout from '../components/item/layout';
import Team from '../components/team';
import Blockquote from '../components/blockquote';
import Identificators from 'components/googleAnalyticsIdentificators';
import GetConsultation from 'components/free-consultation';
import Technology from 'components/technology';
// other
import { meta } from '../../../metaData';
const Routes = require('../../../components/routes').Routes;

function EveryPigCase() {
  const data = useStaticQuery(graphql`
    query {
      chrisBomgaars: file(relativePath: { eq: "chris-bomgaars.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 215, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen1: file(relativePath: { eq: "ep-screen-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen2: file(relativePath: { eq: "ep-screen-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen3: file(relativePath: { eq: "ep-screen-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen4: file(relativePath: { eq: "ep-screen-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen5: file(relativePath: { eq: "everypig-screen-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout
      layoutClassName={styles.container}
      score={['Agritech', 'USA', 'November, 2016', 'Ongoing']}
      navBtn="#FB5A2C"
      headerTitle="Helping Everypig to address the health issues in swine industry"
      subTitle="A case study on developing a groundbreaking management software for pig owners, producers and veterinarians."
      className={styles.header}
      metaData={meta.everyPig}
    >
      <div className={itemStyles.inner}>
        <p className={itemStyles.preamble}>
          Chris Bomgaars grew up in Orange City, Iowa. In 2008, he saw an opportunity to join the
          family pork production business. The venture was successful and scaled from about 30 farms
          to over 350 farms and started raising over 1.5 million pigs per year (2018). As the
          company grew, the problems went from spiking death loss to upraising caregivers’
          complaints. In search of an answer to his company’s issues, Chris searched for a market
          solution, but none existed. That’s when EveryPig was born, a first-of-its-kind pig health
          and production management platform.
        </p>
      </div>
      <Blockquote
        img={data.chrisBomgaars.childImageSharp.fluid}
        text={`“We started EveryPig because we saw an incredible opportunity to bring technology to pork producers while increasing transparency in the swine industry. To that end, we’re always striving.”`}
        author={
          <React.Fragment>
            <b>Chris Bomgaars</b>,
            <br />
            Founder, CEO, EveryPig, Inc.
          </React.Fragment>
        }
        link={{ to: 'https://www.everypig.com/', title: 'everypig.com' }}
      />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Business Challenges</h2>
        <p className={itemStyles.paragraph}>
          For the last two decades, farming in the US has changed a lot. Small companies merged with
          bigger ones to survive. The sector moved to the contracting basis. It means that farm
          owners became growers. In terms of the new system, they own buildings and land, but their
          production belongs to the contractor company. In return, contractors provide feed,
          veterinary and transportation services.
        </p>
        <p className={itemStyles.paragraph}>
          RC Family Farms is a typical contractor company. They have a wide infrastructure, serving
          more than 350 farms. Among their responsibilities is providing veterinary care for
          animals. If the farm owners or caregivers report health issues, the company has to provide
          the treatment on time.
        </p>
      </div>
      <Img fluid={data.screen1.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          Chris Bomgaars joined RC Family Farms a short while after graduating from the university.
          It is a family business, and he was helping his father to develop their company. He had
          been working there for almost a decade, and their growth was successful.
        </p>
        <p className={itemStyles.paragraph}>
          Though at some moment, Chris realized that the company's scale wasn't under control.
          Incoming complaints from caregivers evidenced the serious challenge: "They would let us
          know about a health problem, and for one reason or another, we didn't respond to that
          health issue quickly enough."
        </p>
        <p className={itemStyles.paragraph}>
          On the other hand, veterinarians were often called to come to the farm when the diseases
          were spread out, and their work often seemed to be as if they were putting out a fire. The
          miscommunication between vets and farm growers (who will be called the caregivers further)
          led to more dead pigs, which is bad for a number of reasons, obviously financial, but also
          for animal health and welfare.
        </p>
        <h2 className={itemStyles.title}>Investigating the Problem</h2>
        <p className={itemStyles.paragraph}>
          The deep dive into the problem showed that veterinarians couldn't react on time without
          receiving the actual pigs' health data. The information based on paper barn sheets and
          check-ups went too late to the office. More often, caregivers sent the summary reports at
          the end of the week, mentioning the sickness and mortality statistics.
        </p>
        <p className={itemStyles.paragraph}>
          Chris was looking for any helpful tool to organize the communication, helping them to
          track the health issues and receive responses on time. But he wondered to know that there
          wasn't any relevant software for that moment.
        </p>
      </div>
      <Blockquote
        img={data.chrisBomgaars.childImageSharp.fluid}
        text={`"I couldn't find any real-time solutions in the market, and I started to think, this is absolutely crazy. You know, we're in a technological age where we can be applying some tools to this, and we can start to look at real-time data from the farm and respond to the health issues before the animals are dead. It seemed common sense, but no one had been there doing it."`}
        author={
          <React.Fragment>
            <b>Chris Bomgaars</b>,
            <br />
            Founder, CEO, EveryPig, Inc.
          </React.Fragment>
        }
        link={{ to: 'https://www.everypig.com/', title: 'everypig.com' }}
      />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>The Solution Design</h2>
        <p className={itemStyles.paragraph}>
          The absence of the desired platform on the market encouraged Chris to build a custom
          software solution. His essential advantage was a good understanding of the user portrait,
          their background and expectations.
        </p>
        <p className={itemStyles.paragraph}>
          The main priority of Chris was to digitize the recording process of the data, used by
          various stakeholders. He acknowledged that the user flow should be as simple as possible,
          not requiring lots of technical skills.
        </p>
        <p className={itemStyles.paragraph}>
          Soon after, Chris found a partner, Nick Bartlett, who had helped him outline the product
          requirements and deliver the first version of the UI/UX prototype. Working on it, they
          came up with the patterns familiar to their users from the real world:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>
            <b>The Daily Check-ups</b>, which operate as a short survey.
          </li>
          <li>
            <b>The Farmfeed</b>, close to Facebook's news feed view.
          </li>
          <li>
            <b>The Electronic Barnsheets</b>, eliminating the paperwork.
          </li>
        </ul>
        <p className={itemStyles.paragraph}>
          With daily check-ups, caregivers were enabled to provide veterinarians with meaningful
          data: symptoms, water consumption, temperature and medications usage. Along with that,
          they could attach photos and videos (pictures of pigs or the environment) to the daily
          check-ups. Receiving it on time, veterinarians can make a diagnosis and recommend the
          treatment.
        </p>
      </div>
      <Img
        fluid={data.screen2.childImageSharp.fluid}
        className={clsx(itemStyles.container, styles.screen2)}
        alt=""
      />
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          Based on the design prototype, Chris managed to develop a working demo with his team.
          While testing it with the veterinarians and caregivers, he came up with new insights.
          There was one critical concern related to the quality of diagnostics:{' '}
        </p>
      </div>
      <Blockquote
        img={data.chrisBomgaars.childImageSharp.fluid}
        text={`"What we see happening in the field is 95% of the diagnoses are not getting lab confirmation. And so we have separated into two different types of diagnosis in the app: a clinical and the lab-confirmed diagnosis."`}
        author={
          <React.Fragment>
            <b>Chris Bomgaars</b>,
            <br />
            Founder, CEO, EveryPig, Inc.
          </React.Fragment>
        }
        link={{ to: 'https://www.everypig.com/', title: 'everypig.com' }}
      />
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          Hence, Chris envisioned one more point to add to the product's value. Besides real-time
          communication, the platform would ensure a high-quality diagnosis and mortality
          forecasting.
        </p>
        <p className={itemStyles.paragraph}>
          The pilot project had been tested with stakeholders of RC Family, and after gaining
          feedback, moved to a product launch phase.
        </p>
      </div>
      <div className={itemStyles.container}>
        <GetConsultation
          text="Schedule a call with our experts to get a free consultation"
          title="Are you looking to build an AgriTech solution?"
          linkText="Schedule a call"
          linkId={Identificators.GET_A_FREE_CONSULTATION_BANNER}
        />
      </div>
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Choosing a Vendor</h2>
        <p className={itemStyles.paragraph}>
          Building business-class software requires many development resources. Besides sales and
          marketing campaigns, the founder has to oversee the technical processes. To make their
          work more efficient, Chris decided to outsource the software development to a digital
          agency.
        </p>
        <p className={itemStyles.paragraph}>
          In the fall of 2016, Chris had many meetings with the potential contractors, and
          eventually, he decided to move on with the Brocoders. He explained the criteria of
          choosing our team during his presentation on tech meetup in Sumy: "You know, if you are a
          non-technical guy, you will always be missing some crucial details, which technicians
          already are aware of. You can lose your confidence in the product while talking with some
          of the software developers. The reason is the big ego of programmers, who don't have
          enough skills to level up with your background. But with Brocoders, I obtained
          peer-to-peer communication. Their team guided me at those points that I lacked, so we
          worked smoothly".
        </p>
        <h2 className={itemStyles.title}>Launching a Product</h2>
        <p className={itemStyles.paragraph}>
          Our first task was to refine the project's MVP and help Chris prepare for the sales
          operations on the market. Ultimately, we were required to accomplish the following work:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>Refine the UI/UX based on layouts provided by the EveryPig team;</li>
          <li>Ensure the streamlined work of the features in a browser;</li>
          <li>Implement cloud data storage for media files.</li>
        </ul>
        <p className={itemStyles.paragraph}>
          To complete the first release of EveryPig, we allocated this team to the project:
        </p>
        <Team
          color="#FD5C2E"
          list={[
            { count: 1, title: 'Project manager, leading a development process' },
            { count: 2, title: 'Front-end developers, accomplishing code of the UI design' },
            { count: 2, title: 'Back-end developers, working on architecture design and API' },
          ]}
        />

        <Technology list={['rails', 'react', 'postgreSQL', 'aws']} />
        <p className={itemStyles.paragraph}>
          For the AI-based part, Chris engaged another software development team specializing in
          Machine Learning and Artificial Intelligence. We often communicate with them in terms of
          back-end integrations.
        </p>
        <h2 className={itemStyles.title}>Technical Challenges</h2>
        <p className={itemStyles.paragraph}>
          From the very beginning, our team was provided with the fully documented requirements and
          designs. We showed good progress; however, we faced some challenges:
        </p>
        <h3 className={itemStyles.subtitle}>Confusion with the timezones</h3>
        <p className={itemStyles.paragraph}>
          While developing the daily check-ups, we discovered a significant issue. In some cases,
          the submitted reports showed the wrong date, mainly of the previous one. We received this
          claim from an early customer of EveryPig. Their farms were located in different US states
          with various time zones. So, if the caregiver was behind the vet's timezone, the daily
          check-ups were recognized as of yesterday.
        </p>
        <p className={itemStyles.paragraph}>
          For the service app, which implied real-time communication between the stakeholders, it
          was a crucial issue. Our back-end developer tackled this problem. He applied the UTC
          offset and added the geolocation feature for each farm account. When registering a farm to
          the platform, an admin could view the recommended time zone and approve it. So, the
          multi-zone support helped EveryPig avoid confusion between the dates and showed the actual
          time and date corresponding to a user's location.
        </p>
        <h3 className={itemStyles.subtitle}>Low Internet connection in the rural areas</h3>
        <p className={itemStyles.paragraph}>
          Poor cellular coverage is a common problem for many farm locations in the US. This problem
          would slow down the growth of EveryPig since it is sensitive to the Internet connection.
          To make it possible, we implemented the offline mode for the web application. With this
          feature, caregivers can submit a daily check-up when they don’t have a stable Wi-Fi
          connection or stay in an area with poor cellular coverage
        </p>
      </div>
      <Img
        fluid={data.screen3.childImageSharp.fluid}
        className={clsx(itemStyles.container, styles.screen3)}
        alt=""
      />
      <Blockquote
        img={data.chrisBomgaars.childImageSharp.fluid}
        text={`"We have an offline mode built which allows users to enter information even when they don't have a signal and when they get back to an area with a signal, it automatically uploads. And that's definitely helped."`}
        author={
          <React.Fragment>
            <b>Chris Bomgaars</b>,
            <br />
            Founder, CEO, EveryPig, Inc.
          </React.Fragment>
        }
        link={{ to: 'https://www.everypig.com/', title: 'everypig.com' }}
      />
      <div className={itemStyles.inner}>
        <h3 className={itemStyles.subtitle}>Graphic rendering on the user side</h3>
        <p className={itemStyles.paragraph}>
          EveryPig makes it possible for veterinarians to expand, scroll and annotate the pictures
          added to the daily check-ups. Using these features, veterinarians could faster analyze and
          send their feedback regarding a new case. However, this opportunity has some cost, as it
          requires advanced rendering in a browser.
        </p>
        <p className={itemStyles.paragraph}>
          We utilized a few React.js libraries, which helped us save our time for the development.
          React Image Gallery and React Images Viewer provided us with enough components to
          accomplish this work.
        </p>
        <p className={itemStyles.paragraph}>
          Now veterinarians comment on the uploaded assets, review the images of dead pigs and help
          with symptoms and diagnosis.
        </p>
        <h2 className={itemStyles.title}>Work Process</h2>
        <p className={itemStyles.paragraph}>
          We developed the project iteratively using the sprint system. Each sprint lasted three
          weeks. By the end of it, we presented new features. This helped Chris issue new product
          releases faster and report on functionality updates to his customers and partners.
        </p>
        <p className={itemStyles.paragraph}>
          All the processes were tracked with Trello and Jira management systems. Usage of these
          tools allowed Chris to view the current progress and oversee the entire work.
        </p>
        <p className={itemStyles.paragraph}>
          Our project manager established a few conference calls per week with our client,
          discussing the development work. They held their communications over Skype and Slack.
        </p>
        <h2 className={itemStyles.title}>Growth Story</h2>
        <p className={itemStyles.paragraph}>
          Once we finished the first product release, Chris invited 198 users to the platform. 38
          users completed their registration and began using the system in a month.
        </p>
        <p className={itemStyles.paragraph}>
          There was one successful case that helped his early customer to get the real benefit from
          the product. EveryPig helped them to indicate the serious disease and prevent its further
          spread.
        </p>
        <h2 className={itemStyles.title}>Further Development</h2>
        <p className={itemStyles.paragraph}>
          We keep working on the EveryPig, adding new features and providing full technical support
          to it. Ever since we started its development, the product has been significantly modified.
          Now it combines the features helping automate not only the treatment but the whole
          production process:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>Mortality forecasting;</li>
          <li>Automated reporting;</li>
          <li>Smart suggestions;</li>
          <li>Inventory tracking;</li>
          <li>Site visit reports.</li>
        </ul>
        <p className={itemStyles.paragraph}>
          At present, EveryPig's cloud storage holds over a million pictures from various companies,
          helping its AI module to predict mortality and stop disease outbreaks.
        </p>
      </div>
      <Img
        fluid={data.screen4.childImageSharp.fluid}
        className={clsx(itemStyles.container, styles.screen4)}
        alt=""
      />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Integrations with external service APIs</h2>
        <h3 className={itemStyles.subtitle}>Google maps</h3>
        <p className={itemStyles.paragraph}>
          EveryPig tracks the time and distance between farms and other destinations. These data are
          passed to EveryPig Logistics because they are required for shipment (migrations) purposes.
        </p>
        <h3 className={itemStyles.subtitle}>BarnTool</h3>
        <p className={itemStyles.paragraph}>
          This service app is based on hardware devices placed in the barns. They track the high/low
          temperatures as well as water usage and send it over to the server. We connected to their
          API and used the data within the EveryPig system.
        </p>
        <h3 className={itemStyles.subtitle}>MetaFarms and FAS</h3>
        <p className={itemStyles.paragraph}>
          Client companies used these software products before moving to EveryPig. They kept their
          vet statistics there. The information came in from faxes sent by caregivers. Upon the
          client's request, we managed with the export of statistics from EveryPig. The data they
          need to use arrives at their systems daily.
        </p>
      </div>
      <Blockquote
        img={data.chrisBomgaars.childImageSharp.fluid}
        text={`“Helping pig owners and veterinarians through every step of the production process is exactly what EveryPig was designed to do. And when pigs are healthy, it’s a win not just for the producer, but for the consumer as well.”`}
        author={
          <React.Fragment>
            <b>Chris Bomgaars</b>,
            <br />
            Founder, CEO, EveryPig, Inc.
          </React.Fragment>
        }
        link={{ to: 'https://www.everypig.com/', title: 'everypig.com' }}
      />
      <div className={clsx(itemStyles.inner, itemStyles.lastItem)}>
        <p className={itemStyles.paragraph}>
          Since its inception, Everypig has significantly expanded with new functionality. It now
          includes the features pertaining not only to veterinary but production management and
          delivery. One of the most extensive parts of EveryPig is a{' '}
          <Link to={Routes.CASE_STUDIES_EVERYPIG_LOGISTIC} className={styles.link}>
            Logistics Scheduling module
          </Link>
          , which automates the shipment processes for managers of the production companies.
        </p>
        <p className={itemStyles.paragraph}>
          Created specifically for the pork production industry, EveryPig is used by more than 1,550
          producers and veterinarians in the United States, Canada, and Brazil. The web app is
          currently available in seven languages: English, Portuguese, Spanish, Simplified Chinese,
          Korean, Vietnamese, and French. Production and veterinarian teams across the globe that
          need to enable their remote-work capabilities can request a complimentary demo of
          EveryPig’s platform.
        </p>
      </div>
    </Layout>
  );
}

export default EveryPigCase;
